/**
 * App component with primary route. Initialize services here
 */

import React from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

//import Home from './pages/Home';
import Home from './pages/Home';
import withApplicationState from './store';
import { apiURI, firebaseAnalyticsWebOptions, adMobIds, ENVIRONMENT } from './ts/environment';
import { setupIonicReact } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { AdMob, AdmobConsentStatus, AdmobConsentDebugGeography  } from '@capacitor-community/admob';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import '@capacitor-community/firebase-analytics';
import { App } from '@capacitor/app';

import { platformActionTypes, deviceActionTypes, uuidActionTypes, focusedActionTypes } from './ts/actionTypes';
import AdMobFuncs from './ts/adMob';
import axios from 'axios';

import { runGameTester } from './ts/gameTester';

setupIonicReact({
  mode: 'md'
});

const getPlatform = async () => {
  const deviceObject = await Device.getInfo();
  const initialPlatform = deviceObject.platform;
  //console.log(platform);
  if ( initialPlatform === 'ios' ) {
    setupIonicReact({
      mode: 'ios'
    });
  } else {
    setupIonicReact({
      mode: 'md'
    });
  } 
  return deviceObject;
}

const logPlatform = getPlatform();
logPlatform.then(value => {
  //console.log('Platform:', value);
});

interface AppProps {
  dispatch: ({ type }: { type: string; payload?: any; }) => void;
};

interface AppState {};

interface screenDim_JSON {
  width:     number;
  height:    number;
}


class Wordigins extends React.Component<AppProps, AppState> {

  /**
   * Avoid changing structure of this function, some things need to be done in order.
   * ================================================================================
   * */
  async componentDidMount() {
    const { platform, model } = (await Device.getInfo());
    console.log(await Device.getId());
    const { identifier } = (await Device.getId());
    const uuid = identifier;
    const screenDim_JSON: screenDim_JSON = {
      width: window.innerWidth * window.devicePixelRatio, height: window.innerHeight * window.devicePixelRatio 
    }
    const screenDim = JSON.stringify(screenDim_JSON);
    //console.log(screenDim);
    
    await Preferences.migrate();

    this.props.dispatch({
      type: platformActionTypes.SET_PLATFORM,
      payload: platform
    });

    this.props.dispatch({
      type: deviceActionTypes.SET_DEVICE,
      payload: model
    });

    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        this.props.dispatch({
          type: focusedActionTypes.SET_FOCUSED
        });
      }
    });

    this.props.dispatch({
      type: uuidActionTypes.SET_UUID,
      payload: uuid
    });

    // Initialize capacitor plugins
    if (platform === 'web') {
      await FirebaseAnalytics.initializeFirebase(firebaseAnalyticsWebOptions);
    } else {
      let platformAdId = (platform === 'ios') ? adMobIds.iosBanner : adMobIds.androidBanner;

      AdMob.initialize()
      .then(() => console.log('AdMob initialized successfully'))
      .catch((error) => console.error('AdMob initialization failed:', error));
    
      // Request and log consent info
      const consentInfo = await AdMob.requestConsentInfo();
      console.log('AdMob consent info:', consentInfo);
      
      if (consentInfo.isConsentFormAvailable && consentInfo.status === AdmobConsentStatus.REQUIRED) {
          const { status } = await AdMob.showConsentForm();
          console.log('AdMob consent form status:', status);
      }
      
      console.log('admob Using Ad Unit ID:', platformAdId);
      
      AdMobFuncs.showBanner(platformAdId);
      AdMobFuncs.hideBanner(); 
    }
    
    FirebaseAnalytics.setCollectionEnabled({enabled: true});

    // Register user in database by device UUID
    try {
      let uuidRes = await axios.post(apiURI + '/user/register', {}, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {});
      //console.log('User Register Response: Status ' + uuidRes.data.status + ', ' + uuidRes.data.status_message);
    } catch (e) {
      console.log('User Registration Failed');
      console.error(e);
    }

    runGameTester();
  }

  render () {
    return (
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/" component={Home} />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    );
  }
};

export default withApplicationState(Wordigins);
