/**
 * Functions for AdMob implementation
 */

import { AdMob, BannerAdOptions, BannerAdSize, BannerAdPosition } from '@capacitor-community/admob';
import { adMobIds, ENVIRONMENT } from './environment';

var adMobFuncs = {
    showBanner,
    hideBanner,
    resumeBanner,
    removeBanner
}

export default adMobFuncs;

function showBanner(adId: string) {
    const options: BannerAdOptions = {
        // Replace with adId for actual banner ids
        //adId: adMobIds.testBanner,
        adId: ENVIRONMENT === 'production' ? adId : adMobIds.testBanner,
        adSize: BannerAdSize.ADAPTIVE_BANNER,
        position: BannerAdPosition.TOP_CENTER,
    };

    console.log('admob Attempting to show banner ad with options:', options);

    AdMob.showBanner(options)
        .then((value: any) => console.log('admob Banner ad shown successfully:', value))
        .catch((error: any) => console.error('admob Failed to show banner ad:', error));
}


function hideBanner() {
    console.log('Attempting to hide banner ad...');
    AdMob.hideBanner()
        .then((value: any) => console.log('admob Banner ad hidden successfully:', value))
        .catch((error: any) => console.error('admob Failed to hide banner ad:', error));
}


function resumeBanner() {
    console.log('Attempting to resume banner ad...');
    AdMob.resumeBanner()
        .then((value: any) => console.log('admob Banner ad resumed successfully:', value))
        .catch((error: any) => console.error('admmob Failed to resume banner ad:', error));
}


function removeBanner() {
    console.log('Attempting to remove banner ad...');
    AdMob.removeBanner()
        .then((value: any) => console.log('admob Banner ad removed successfully:', value))
        .catch((error: any) => console.error('admob Failed to remove banner ad:', error));
}


