import React from 'react';
import { IonIcon, IonLabel, IonSegment, IonSegmentButton, IonButton } from '@ionic/react';
import '../theme/Scores.css';

import axios from 'axios';

import { Haptics, ImpactStyle } from '@capacitor/haptics';
import withApplicationState from '../store';
import { ScoreRecord, RankingRecord, ApplicationState } from '../ts/interfaces';
import { apiURI, ENVIRONMENT } from '../ts/environment';
import { Preferences } from '@capacitor/preferences';
import { Howl } from 'howler';
import AdMobFuncs from '../ts/adMob';
import { correctResponseActionTypes, 
    countCorrectActionTypes, 
    lastResponseActionTypes, 
    questionIndexActionTypes, 
    questionsArrActionTypes, 
    quizInProgressActionTypes, 
    submittedActionTypes, } from '../ts/actionTypes';

import iconRandom from '../assets/icons/random/icons_wordigins_random_white.svg';
import iconBrands from  '../assets/icons/brand/icons_wordigins_brand_white.svg';
import iconSports from  '../assets/icons/sports/icons_wordigins_sports_white.svg';
import iconArts   from  '../assets/icons/arts/icons_wordigins_arts_white.svg';
import iconMoney  from '../assets/icons/money/icons_wordigins_money_white.svg';
import iconImitative from '../assets/icons/imitative/icons_wordigins_imitative_white.svg';
import iconFood from '../assets/icons/food/icons_wordigins_food_white.svg';
import iconImported from '../assets/icons/imported/icons_wordigins_import_white.svg';
import iconAll from '../assets/icons/all/icons_wordigins_all_white.svg';

import iconRandomOrange from '../assets/icons/random/icons_wordigins_random_orange.svg';
import iconBrandsOrange from  '../assets/icons/brand/icons_wordigins_brand_orange.svg';
import iconSportsOrange from  '../assets/icons/sports/icons_wordigins_sports_orange.svg';
import iconArtsOrange   from  '../assets/icons/arts/icons_wordigins_arts_orange.svg';
import iconMoneyOrange  from '../assets/icons/money/icons_wordigins_money_orange.svg';
import iconImitativeOrange from '../assets/icons/imitative/icons_wordigins_imitative_orange.svg';
import iconFoodOrange from '../assets/icons/food/icons_wordigins_food_orange.svg';
import iconImportedOrange from '../assets/icons/imported/icons_wordigins_import_orange.svg';
import iconAllOrange from '../assets/icons/all/icons_wordigins_all_orange.svg';

import iconRandomBrown from '../assets/icons/random/icons_wordigins_random_brown.svg';
import iconBrandsBrown from  '../assets/icons/brand/icons_wordigins_brand_brown.svg';
import iconSportsBrown from  '../assets/icons/sports/icons_wordigins_sports_brown.svg';
import iconArtsBrown   from  '../assets/icons/arts/icons_wordigins_arts_brown.svg';
import iconMoneyBrown  from '../assets/icons/money/icons_wordigins_money_brown.svg';
import iconImitativeBrown from '../assets/icons/imitative/icons_wordigins_imitative_brown.svg';
import iconFoodBrown from '../assets/icons/food/icons_wordigins_food_brown.svg';
import iconImportedBrown from '../assets/icons/imported/icons_wordigins_import_brown.svg';
import iconAllBrown from '../assets/icons/all/icons_wordigins_all_brown.svg'

import NoInternetWarning    from '../components/NoInternetWarning';

interface ScoresProps {
    store:             ApplicationState;
    dispatch:          ({ type }: { type: string; payload?: any; }) => void;
    startGameCallback:     () =>     void;
}

interface ScoresState {
    scopeFilter:         string;
    diffFilter:          string;
    catFilter:           string;
    timeframeFilter:     string;
    colSort:             string;
    colAsc:              boolean;
    filterOpen:          boolean;
    explanationOpen:     boolean;
    gotScores:           boolean;

    userScores:          Array<ScoreRecord>;
    filteredScores:      Array<ScoreRecord>;

    dayRankings:         Array<RankingRecord>;
    weekRankings:        Array<RankingRecord>;
    monthRankings:       Array<RankingRecord>;
    filteredRankings:    Array<RankingRecord>;
    internetModalOpen:   boolean;
}

class Scores extends React.Component<ScoresProps, ScoresState> {

    constructor (props: ScoresProps) {
        super(props);

        this.state = {      
            scopeFilter:           'local',
            catFilter:             'all',
            diffFilter:            'all',
            timeframeFilter:       'all',
            colSort:               'date',
            colAsc:                true,
            filterOpen:            false,
            explanationOpen:       false,
            gotScores:             false,

            userScores:            [],
            filteredScores:        [],
     
            dayRankings:           [],
            weekRankings:          [],
            monthRankings:         [],
            filteredRankings:      [],
            internetModalOpen:     false
        }
    }

    async componentDidMount () {
        // Get all data on page load
        await this.refreshData();
        //workaround to load data on pageload without edge case of switching back scope from user selection
        this.setState({
        }, () => this.filterData());
        this.columnSort('date', false);
        // Default column sort by date descending
        this.columnSort('date', false);
    }

    openInternetModal () {
        this.setState({
          internetModalOpen:   true,
        });
      }
    
      closeInternetModal () {
        console.log("closed internet modal");
        this.setState({
          internetModalOpen:   false,
        });
      }

    /**
     * Procedure to toggle scope according to tab selected
     * @param scope scope to toggle to
     */
    private setScope (scope: string) {
        if (scope === 'local') {

            this.setState({
                scopeFilter: scope,
                catFilter: 'all',
                timeframeFilter: 'all',
                colSort: 'date',
                colAsc: true
            }, () => this.filterData());
            this.columnSort('date', false);

        } else if (scope === 'world') {

            this.setState({
                scopeFilter: scope,
                catFilter: 'all',
                timeframeFilter: 'day',
                colSort: 'rank',
                colAsc: true
            }, () => this.filterData());
            this.columnSort('rank', false);

        }
    }

    private setCategoryFilter (cat: string) {
        this.setState({
            catFilter: cat
        }, () => this.filterData());
    }

    private setTimeframeFilter (range: string) {
        this.setState({
            timeframeFilter: range
        }, () => this.filterData());
    }

    private toggleFilterState () {
        let { filterOpen } = this.state;
    
        //close explanation on open
        if (!filterOpen) {
            this.setState({explanationOpen: false});
        }

        // close if open, open if closed
        filterOpen = !filterOpen;

        this.setState({filterOpen: filterOpen});
    }

    private toggleExplanationState () {
        let { explanationOpen } = this.state;
    
        //close explanation on open
        if (!explanationOpen) {
            this.setState({filterOpen: false});
        }

        // close if open, open if closed
        explanationOpen = !explanationOpen;

        this.setState({explanationOpen: explanationOpen});
    }

    private closeExplanationState () {
        this.setState({explanationOpen: false});
    }
    
    /**
     * Helper function to assign rank numbers according to weighted totals
     * @param rankings array of ranking data to be formatted
     * @returns formatted rankings
     */
    private assignRankings (rankings: Array<RankingRecord>, log: boolean = false) {
        let formattedRankings: Array<RankingRecord> = [];

        rankings.forEach((ranking: any) => {
            // Filter out overall scores for now
            if (ranking.category !== 'overall') {
                ranking.rank = 0;
                formattedRankings.push(ranking);
            }
        });

        // Sort by weighted total and game time if tied
        formattedRankings.sort((a: RankingRecord, b: RankingRecord) => (+ a.weighted_total > + b.weighted_total) ? -1 : (+ a.weighted_total === + b.weighted_total) ? (+ a.game_time < + b.game_time) ? -1 : 1 : 1);

        // Assign rankings
        let lastRank = 0, lastTotal = 0, lastTime = 0;
        formattedRankings.forEach((ranking, i) => {
            
            // Tied rank if same total
            if (ranking.weighted_total === lastTotal) {
                if (+ ranking.game_time === lastTime) {
                    ranking.rank = lastRank;
                } else {
                    ranking.rank = i + 1;
                    lastRank = i + 1;
                    lastTotal = + ranking.weighted_total;
                    lastTime = + ranking.game_time;
                }
            } else {
                ranking.rank = i + 1;
                lastRank = i + 1;
                lastTotal = + ranking.weighted_total;
                lastTime = + ranking.game_time;
            }
            if ( log ) {
                //console.log("ranking: ", + ranking.weighted_total, "--", ranking.rank, "--", + ranking.game_time);
            }
        });

        return formattedRankings;
    }

    /**
     * Procedure to request new data from leaderboards API
     */
    async refreshData () {
        const { uuid } = this.props.store;

        /**
          * GET USER SCORES
          */
        try {
            let scoresRes = await axios.post(apiURI + '/records/get', {uuid: uuid}, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {});
            let records = scoresRes.data.data.records;
            let formattedScores: Array<ScoreRecord> = [];
            records.forEach((record: any) => {
                formattedScores.push(record);
            });

            this.setState({userScores: formattedScores});
        } catch (e) {
            console.log("Failed to Retrieve User Scores");
            this.openInternetModal();
            console.error(e);
        }

        /**
         * GET WORLDWIDE RANKINGS
         */
        try {
            let dayRankingsRes = await axios.post(apiURI + '/rankings/get', {date_range: 'day'}, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {});
            let dayRankings = dayRankingsRes.data.data.rankings;
            let formattedDayRankings: Array<RankingRecord> = this.assignRankings(dayRankings);
        
            this.setState({dayRankings: formattedDayRankings});
        } catch (e) {
            console.log("Failed to Retrieve Day Rankings");
            this.openInternetModal();
            console.error(e);
        }

        try {
            let weekRankingsRes = await axios.post(apiURI + '/rankings/get', {date_range: 'week'}, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {});
            let weekRankings = weekRankingsRes.data.data.rankings;
            let formattedWeekRankings: Array<RankingRecord> = this.assignRankings(weekRankings);

            this.setState({weekRankings: formattedWeekRankings});
        } catch (e) {
            console.log("Failed to Retrieve Week Rankings");
            this.openInternetModal();
            console.error(e);
        }

        try {
            let monthRankingsRes = await axios.post(apiURI + '/rankings/get', {date_range: 'month'}, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {});
            let monthRankings = monthRankingsRes.data.data.rankings;
            let formattedMonthRankings: Array<RankingRecord> = this.assignRankings(monthRankings, true);

            this.setState({monthRankings: formattedMonthRankings});
            this.setState({gotScores: true});
        } catch (e) {
            console.log("Failed to Retrieve Month Rankings");
            this.openInternetModal();
            console.error(e);
        }
    }

    /**
     * Filter scores and rankings array to specific category and timeframe defined in state
     */
    private filterData () {
        const { catFilter, timeframeFilter } = this.state;
        let filteredScores = this.state.userScores;

        let filteredRankings: Array<RankingRecord>;
        switch (timeframeFilter) {
            case 'day':
                filteredRankings = [...this.state.dayRankings];
                break;
            case 'week':
                filteredRankings = [...this.state.weekRankings];
                break;
            case 'month':
                filteredRankings = [...this.state.monthRankings];
                break;
            default:
                filteredRankings = [...this.state.dayRankings];
        }
    
        if (catFilter !== 'all') {            filteredScores = filteredScores.filter(scoreEntry => {
                const match = scoreEntry.category === catFilter;
                if (!match) console.log("Excluding scoreEntry:", scoreEntry);
                return match;
            });
            filteredRankings = filteredRankings.filter(rankingEntry => {
                const match = rankingEntry.category === catFilter;
                if (!match) console.log("Excluding rankingEntry:", rankingEntry);
                return match;
            });
            // Recalculate ranks for filtered rankings
            filteredRankings.sort((a: RankingRecord, b: RankingRecord) =>
                b.weighted_total - a.weighted_total || a.game_time - b.game_time
            );
            let rank = 1;
            filteredRankings.forEach((ranking, index) => {
                if (
                    index > 0 &&
                    filteredRankings[index - 1].weighted_total === ranking.weighted_total &&
                    filteredRankings[index - 1].game_time === ranking.game_time
                ) {
                    ranking.rank = filteredRankings[index - 1].rank; // Assign same rank for ties
                } else {
                    ranking.rank = rank; // Assign the next rank
                }
                rank++;
            });
        } else {
            // General sorting when no filter is applied
            filteredRankings.sort((a: RankingRecord, b: RankingRecord) =>
                b.weighted_total - a.weighted_total || a.game_time - b.game_time
            );
    
            // Reassign ranks properly to handle duplicates
            let rank = 1;
            filteredRankings.forEach((ranking, index) => {
                if (
                    index > 0 &&
                    filteredRankings[index - 1].weighted_total === ranking.weighted_total &&
                    filteredRankings[index - 1].game_time === ranking.game_time
                ) {
                    ranking.rank = filteredRankings[index - 1].rank; // Assign same rank for ties
                } else {
                    ranking.rank = rank; // Assign the next rank
                }
                rank++;
            });
        }
        this.setState({
            filteredScores: filteredScores,
            filteredRankings: filteredRankings
        });
    }
    
    /**
     * Sort scores/rankings array in state by column asc/desc
     * @param col column to sort by
     * @param setAsc asc = true, desc = false
     */
    private columnSort (col: string, setAsc: boolean = true) {
        const { scopeFilter, colSort } = this.state;
        let { colAsc } = this.state;
        let scores = this.state.filteredScores;
        let rankings  = this.state.filteredRankings;

        // Set colAsc 
        if (setAsc) {
            // Toggle if using the same column, otherwise default to desc
            if (colSort === col || (col === "score_world" && colSort === 'score')) {
                // Set within scope, then set in state
                colAsc = !colAsc;
            } else {
                colAsc = false;
            }
            this.setState({colAsc: colAsc});
        }

        // Set column currently sorted
        this.setState({colSort: col});
        if (col === "score_world") {
            this.setState({colSort: "score"});
        }

        // Map difficulties to absolute relative value (included for sorting and future-proofing difficulty)
        let diffMap: {[key: string]: number} = {
            'easy': 0,
            'moderate': 1,
            'hard': 2
        }

        // Always sort by date or weighted_total desc first
        scores.sort((a: ScoreRecord, b: ScoreRecord) => (a.date < b.date) ? 1 : -1);
        rankings.sort((a: RankingRecord, b: RankingRecord) => (+ a.weighted_total < + b.weighted_total) ? 1 : -1);

        // Then apply fine sort
        switch (col) {
            case 'score':
                //console.log("sorting by score");
                if (colAsc) {
                    scores.sort((a: ScoreRecord, b: ScoreRecord) => 
                        (a.score / a.num_questions > b.score / b.num_questions) ? 
                            1 
                        :
                            (a.score / a.num_questions === b.score / b.num_questions) ?
                                (+ a.game_time < + b.game_time) ?
                                    1
                                :
                                    -1
                            :
                                -1);
                } else {
                    scores.sort((a: ScoreRecord, b: ScoreRecord) => 
                        (a.score / a.num_questions < b.score / b.num_questions) ? 
                            1 
                        :
                            (a.score / a.num_questions === b.score / b.num_questions) ?
                                (+ a.game_time < + b.game_time) ?
                                    -1
                                :
                                    1
                            :
                                -1);
                }
                break;
            case 'rank':
                if (colAsc) {
                    rankings.sort((a: RankingRecord, b: RankingRecord) => 
                        (+ a.rank > + b.rank) ? 
                            1 
                        :
                            (+ a.rank === b.rank) ?
                                (+ a.game_time < + b.game_time) ?
                                    -1
                                :
                                    1
                            :
                                -1);

                } else {
                    rankings.sort((a: RankingRecord, b: RankingRecord) => 
                        (+ a.rank < + b.rank) ? 
                            1 
                        :
                            (+ a.rank === b.rank) ?
                                (+ a.game_time < + b.game_time) ?
                                    1
                                :
                                    -1
                            :
                                -1);
                }
                break;
            case 'initials':
                if (colAsc) {
                    scores.sort((a: ScoreRecord, b: ScoreRecord) => (a.initials < b.initials) ? 1 : -1);
                    rankings.sort((a: RankingRecord, b: RankingRecord) => (a.initials < b.initials) ? 1 : -1);
                } else {
                    scores.sort((a: ScoreRecord, b: ScoreRecord) => (a.initials > b.initials) ? 1 : -1);
                    rankings.sort((a: RankingRecord, b: RankingRecord) => (a.initials > b.initials) ? 1 : -1);
                }
                break;
            case 'date':
                if (colAsc) {
                    scores.sort((a: ScoreRecord, b: ScoreRecord) => (a.date > b.date) ? 1 : -1);
                } else {
                    scores.sort((a: ScoreRecord, b: ScoreRecord) => (a.date < b.date) ? 1 : -1);
                }
                break;
            case 'diff':
                if (colAsc) {
                    scores.sort((a: ScoreRecord, b: ScoreRecord) => (diffMap[a.difficulty] > diffMap[b.difficulty]) ? 1 : -1);
                    rankings.sort((a: RankingRecord, b: RankingRecord) => (diffMap[a.difficulty] > diffMap[b.difficulty]) ? 1 : -1);
                } else {
                    scores.sort((a: ScoreRecord, b: ScoreRecord) => (diffMap[a.difficulty] < diffMap[b.difficulty]) ? 1 : -1);
                    rankings.sort((a: RankingRecord, b: RankingRecord) => (diffMap[a.difficulty] < diffMap[b.difficulty]) ? 1 : -1);
                }
                break;
            case 'cat':
                if (colAsc) {
                    scores.sort((a: ScoreRecord, b: ScoreRecord) => (a.category > b.category) ? 1 : -1);
                    rankings.sort((a: RankingRecord, b: RankingRecord) => (a.category > b.category) ? 1 : -1);
                } else {
                    scores.sort((a: ScoreRecord, b: ScoreRecord) => (a.category < b.category) ? 1 : -1);
                    rankings.sort((a: RankingRecord, b: RankingRecord) => (a.category < b.category) ? 1 : -1);
                }
                break;
            case 'score_world':
                if (colAsc) {
                    rankings.sort((a: RankingRecord, b: RankingRecord) => 
                        (+ a.weighted_total > + b.weighted_total) ? 
                            1 
                        :
                            (+ a.weighted_total === b.weighted_total) ?
                                (+ a.game_time < + b.game_time) ?
                                    1
                                :
                                    (+ a.game_time === + b.game_time) ?
                                        (+ a.weighted_total > + b.weighted_total) ?
                                            1
                                        :
                                            -1
                                    :
                                        -1
                            :
                                -1);

                
                } else {
                    rankings.sort((a: RankingRecord, b: RankingRecord) => 
                        (+ a.weighted_total < + b.weighted_total) ? 
                            1 
                        :
                            (+ a.weighted_total === b.weighted_total) ?
                                (+ a.game_time < + b.game_time) ?
                                    -1
                                :
                                    (+ a.game_time === + b.game_time) ?
                                        (+ a.weighted_total > + b.weighted_total) ?
                                            -1
                                        :
                                            1
                                    :
                                        1
                            :
                                -1);
                }
                break;
            case 'time':
                if (colAsc) {
                    scores.sort((a: ScoreRecord, b: ScoreRecord) => 
                        (+ a.game_time > + b.game_time) ? 
                            1 
                        :
                            -1);
                    rankings.sort((a: RankingRecord, b: RankingRecord) => 
                        (+ a.game_time > + b.game_time) ? 
                            1 
                        :
                            -1);
                } else {
                    scores.sort((a: ScoreRecord, b: ScoreRecord) => 
                        (+ a.game_time < + b.game_time) ? 
                            1 
                        :
                            -1);
                    rankings.sort((a: RankingRecord, b: RankingRecord) => 
                        (+ a.game_time < + b.game_time) ? 
                            1 
                        :
                            -1);
                }
                break;
            default: 
                if (scopeFilter === 'local') {
                    scores.sort((a: ScoreRecord, b: ScoreRecord) => (a.date < b.date) ? 1 : -1); 
                } else {
                    rankings.sort((a: RankingRecord, b: RankingRecord) => 
                        (+ a.rank > + b.rank) ? 
                            -1 
                        :
                            (+ a.rank === b.rank) ?
                                (+ a.game_time < + b.game_time) ?
                                    1
                                :
                                    -1
                            :
                                1);
                }
        }

        this.setState({
            filteredScores: scores,
            filteredRankings: rankings
        });
    }

    /** 
     * Helper function for category icons
     */
    private getIcon (cat: string, brown: boolean = false) {
        switch (cat) {                        
            case 'random':
                return brown ? iconRandomBrown : iconRandom;
            case 'brands':
                return brown ? iconBrandsBrown : iconBrands;
            case 'sports':
                return brown ? iconSportsBrown : iconSports;
            case 'arts':
                return brown ? iconArtsBrown : iconArts;
            case 'money':
                return brown ? iconMoneyBrown : iconMoney;
            case 'imitative':
                return brown ? iconImitativeBrown : iconImitative;
            case 'food':
                return brown ? iconFoodBrown : iconFood;
            case 'imported':
                return brown ? iconImportedBrown : iconImported;
            case 'all':
                return brown ? iconAllBrown : iconAll;
            default:
                return undefined;
        }
    }

     /** 
     * Helper function for category labels
     */
      private getLabel (cat: string) {
        switch (cat) {                        
            case 'random':
                return "Random"
            case 'brands':
                return "Brands"
            case 'sports':
                return "Sports"
            case 'arts':
                return "Arts"
            case 'money':
                return "Money"
            case 'imitative':
                return "Imitative"
            case 'food':
                return "Food"
            case 'imported':
                return "Imported"
            case 'all':
                return "All"
            default:
                return undefined;
        }
    }


    /**
   * Procedure for ending quiz and returning to setup screen
   * Reset all relevant global vars and resume ad banner
   */
  endQuiz() {
    const { platform, soundsOn, vibrationOn, uiAudio, focused } = this.props.store;
    const { startGameCallback } = this.props
    if (focused && soundsOn) {
      const uiPopAudio: Howl = new Howl({
        src: ['assets/pop.mp3'],
        format: ['mp3'],
        volume: 0.3,
        onloaderror: (id, e) => console.log('failed: ' + id + ' msg: ' + e)
    });
    uiPopAudio.play();
    } else {
      if (soundsOn) uiAudio.play();
    }

    if (platform !== 'web' && vibrationOn) Haptics.impact({style: ImpactStyle.Light});

    this.props.dispatch({
      type: questionsArrActionTypes.SET_QUESTIONS_ARR,
      payload: []
    });

    this.props.dispatch({
      type: questionIndexActionTypes.SET_INDEX,
      payload: undefined
    });

    this.props.dispatch({
      type: quizInProgressActionTypes.SET_NOT_IN_PROGRESS,
    });

    this.props.dispatch({
      type: submittedActionTypes.SET_NOT_SUBMITTED
    });

    this.props.dispatch({
      type: correctResponseActionTypes.SET_INCORRECT
    });

    this.props.dispatch({
      type: lastResponseActionTypes.SET_RESPONSE,
      payload: ''
    });

    this.props.dispatch({
      type: countCorrectActionTypes.SET_COUNT,
      payload: 0
    });

    if (platform !== 'web') AdMobFuncs.resumeBanner();

    startGameCallback();

    try {
        Preferences.set({
            key: 'savedGame',
            value: '0'
          });
          Preferences.remove({
            key: 'countCorrectKey',
          });
          Preferences.remove({
            key: 'currentQuestionIndexKey',
          });
          Preferences.remove({
            key: 'currentCategoryKey',
          });
          Preferences.remove({
            key: 'currentDifficultyKey',
          });
          Preferences.remove({
            key: 'scoreSubmittedKey',
          });
          Preferences.remove({
            key: 'currentQuestionsArrayKey',
          });
    } catch (e) {
      console.error(e);
    }
  }

    render () {
        const { uuid, quizInProgress } = this.props.store;
        const { startGameCallback } = this.props
        let { scopeFilter, catFilter, timeframeFilter, colSort, colAsc, filteredScores, filteredRankings, filterOpen, explanationOpen, gotScores, internetModalOpen } = this.state;

        let colSortClasses = 'column-selected';
        if (colAsc) {
            colSortClasses += ' column-asc';
        } else {
            colSortClasses += ' column-desc';
        }

        let scoreboard: Array<JSX.Element> = [];
        if (scopeFilter === 'local') {

            // Find latest entry
            let latestDate = '0';
            filteredScores.forEach(scoreRecord => {
                if (scoreRecord.date > latestDate) latestDate = scoreRecord.date;
            });

            filteredScores.forEach((scoreRecord, i) => {
                let date = new Date(+ scoreRecord.date * 1000);
                
                // Set category icon for record
                let categoryIcon = this.getIcon(scoreRecord.category); 

                let classes = 'score-record';
                if (scoreRecord.date === latestDate) classes += ' latest';
                scoreboard.push(
                    <tr className={classes} key={i}>
                        <td>{scoreRecord.score}</td>
                        <td>{+ scoreRecord.game_time === 9999000 ? 9999 : Math.round(+ scoreRecord.game_time/1000)}</td>
                        <td>{date.toLocaleString().split(",")[0]}</td>
                        <td>{scoreRecord.difficulty === "moderate" ? "MOD" : scoreRecord.difficulty}</td>
                        <td><IonIcon icon={categoryIcon} /></td>
                    </tr>
                );
            });

        } else if (scopeFilter === 'world') {
            filteredRankings.forEach((rankingRecord, i) => {
                let yours = rankingRecord.uuid === uuid;
                
                // Set category icon for record
                let categoryIcon = this.getIcon(rankingRecord.category, yours);

                // Detect if score belongs to current user in worldwide scope
                let classes = 'score-record';
                if (yours) classes += ' yours';
                scoreboard.push(
                    <tr className={classes} key={i}>
                        <td>{rankingRecord.rank}</td>
                        <td>{Math.round(rankingRecord.weighted_total)}</td>
                        <td>{+ rankingRecord.game_time === 9999000 ? 9999 : Math.round(+ rankingRecord.game_time/1000)}</td>
                        <td>{rankingRecord.difficulty === "moderate" ? "MOD" : rankingRecord.difficulty}</td>
                        <td>{rankingRecord.initials}</td>
                        <td><IonIcon icon={categoryIcon} /></td>
                    </tr>
                );
            });

        }        


        return (
            <>
                <div className="scores-wrapper">
                    <h1>SCOREBOARD</h1>
                    <IonSegment mode="md" className="scope-selector" value={scopeFilter} onIonChange={e => this.setScope(String(e.detail.value) || scopeFilter)}>
                        <IonSegmentButton value="local">YOURS</IonSegmentButton>
                        <IonSegmentButton value="world">WORLDWIDE</IonSegmentButton>
                    </IonSegment>

                <div className="scope-ui">
                <NoInternetWarning internetModalOpen={internetModalOpen}
                                homeCallback={startGameCallback}/>
                { (scopeFilter === 'world') ?
                        <React.Fragment>
                            <IonLabel className="instruction-label">Filter by Timeframe:</IonLabel>
                            <IonSegment mode="md" className="date-range-selector" 
                                        value={timeframeFilter} 
                                        onIonChange={e => this.setTimeframeFilter(String(e.detail.value) || timeframeFilter)}>
                                <IonSegmentButton value="day">Today</IonSegmentButton>
                                <IonSegmentButton value="week">Week</IonSegmentButton>
                                <IonSegmentButton value="month">Month</IonSegmentButton>
                            </IonSegment>
                        </React.Fragment>
                        :
                        <React.Fragment />
                    }
                    <div className="cat-row">
                        <IonLabel onClick={() => this.toggleFilterState()} className={(filterOpen) ? 'instruction-label scores-open' : 'instruction-label scores-closed'}>Filter by Category:  </IonLabel>
                        <IonIcon id="cat-icon"  icon={this.getIcon(catFilter, false)} />
                        <IonLabel id="cat-label"> {this.getLabel(catFilter)}</IonLabel>
                        
                    </div>
                    {filterOpen && 
                    <IonSegment mode='md' className='category-selector' value={catFilter} 
                        onIonChange={e => {this.setCategoryFilter(String(e.detail.value) || catFilter); filterOpen = false; setTimeout(() => (this.setState({filterOpen: filterOpen})), 50)}}>
                                {/* onIonChange={(e => this.setCategoryFilter((String(e.detail.value) === catFilter) ? 'all' : e.detail.value || catFilter))}> */}
                        <IonSegmentButton value='all'>
                            <IonIcon icon={(catFilter === 'all') ? iconAllBrown : iconAllOrange} />
                            <IonLabel>All</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value='random'>
                            <IonIcon icon={(catFilter === 'random') ? iconRandomBrown : iconRandomOrange} />
                            <IonLabel>Random</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value='arts'>
                            <IonIcon icon={(catFilter === 'arts')   ? iconArtsBrown : iconArtsOrange} />
                            <IonLabel>Arts</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value='brands'>
                            <IonIcon icon={(catFilter === 'brands')  ? iconBrandsBrown : iconBrandsOrange} />
                            <IonLabel>Brands</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value='money'>
                            <IonIcon icon={(catFilter === 'money')  ? iconMoneyBrown : iconMoneyOrange} />
                            <IonLabel>Money</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value='sports'>
                            <IonIcon icon={(catFilter === 'sports') ? iconSportsBrown : iconSportsOrange} />
                            <IonLabel>Sports</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value='imitative'>
                            <IonIcon icon={(catFilter === 'imitative') ? iconImitativeBrown : iconImitativeOrange} />
                            <IonLabel>Imitative</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value='food'>
                            <IonIcon icon={(catFilter === 'food') ? iconFoodBrown : iconFoodOrange} />
                            <IonLabel>Food</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value='imported'>
                            <IonIcon icon={(catFilter === 'imported') ? iconImportedBrown : iconImportedOrange} />
                            <IonLabel>Imported</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                    }
                    { (scopeFilter === 'world') ?
                        <React.Fragment>
                            <div className="explanation-wrapper">
                                <IonLabel className="instruction-label">Or Sort by Column:</IonLabel>
                                <IonLabel onClick={() => this.toggleExplanationState()} className={(explanationOpen) ? 'scores-explanation-label explanation-open' : 'scores-explanation-label explanation-closed' } tabIndex={0} onBlur={() => this.closeExplanationState()}>Scores Higher than 10?</IonLabel>
                            </div>
                            <div className="explanation-text-wrapper">
                                { (explanationOpen) ?
                                <p className="explanation-text">We bet you're wondering why some scores are higher than 10. That's because we weight the scores depending on difficulty level. The highest score possible for an easy level game is 10, for a moderate level game it's 13, and for a hard level game, it's 17.</p>
                                :
                                <></>
                                }
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className="explanation-wrapper">
                                <IonLabel className="instruction-label">Or Sort by Column:</IonLabel>
                            </div>
                        </React.Fragment>
                    }
                    <div className="table-wrapper">
                        <table>
                            { (scopeFilter === 'local') ?
                                <thead>
                                    <tr className="score-record headers" key="0">
                                        <th className={(colSort === 'score') ? colSortClasses : ''} onClick={() => this.columnSort('score')}>SCORE</th>
                                        <th className={(colSort === 'time')     ? colSortClasses : ''} onClick={() => this.columnSort('time')}>TIME</th>
                                        <th className={(colSort === 'date')  ? colSortClasses : ''} onClick={() => this.columnSort('date')}>DATE</th>
                                        <th className={(colSort === 'diff')  ? colSortClasses : ''} onClick={() => this.columnSort('diff')}>DIFF</th>
                                        <th className={(colSort === 'cat')   ? colSortClasses : ''} onClick={() => this.columnSort('cat')}>CAT.</th>
                                    </tr>
                                </thead>

                            : (scopeFilter === 'world') ?
                                <thead>
                                    <tr className="score-record headers" key="0">
                                        <th className={(colSort === 'rank')     ? colSortClasses : ''} onClick={() => this.columnSort('rank')}>RANK</th>
                                        <th className={(colSort === 'score')    ? colSortClasses : ''} onClick={() => this.columnSort('score_world')}>SCORE</th>
                                        <th className={(colSort === 'time')     ? colSortClasses : ''} onClick={() => this.columnSort('time')}>TIME</th>
                                        <th className={(colSort === 'diff')     ? colSortClasses : ''} onClick={() => this.columnSort('diff')}>DIFF</th>
                                        <th className={(colSort === 'initials') ? colSortClasses : ''} onClick={() => this.columnSort('initials')}>PLAYER</th>
                                        <th className={(colSort === 'cat')      ? colSortClasses : ''} onClick={() => this.columnSort('cat')}>CAT.</th>
                                    </tr>
                                </thead>
                            :
                                <thead />
                            }
                            <tbody>
                                { (scoreboard.length >= 1 || !gotScores) ?
                                    (!gotScores) ? 
                                            <tr className="no-scores"><td align="center" colSpan={4} rowSpan={2}>Checking for scores...</td></tr>
                                        : 
                                            scoreboard
                                    :
                                    (scopeFilter === 'local') ? 
                                            <tr className="no-scores"><td align="center" colSpan={4} rowSpan={2}>No Scores Yet.<br/><IonButton onClick={!quizInProgress ? () => startGameCallback() : () => this.endQuiz()} className="start-button">PLAY A GAME</IonButton></td></tr>
                                        :
                                            <tr className="no-scores"><td align="center" colSpan={4} rowSpan={2}>No Scores Yet.<br/><IonButton onClick={!quizInProgress ? () => startGameCallback() : () => this.endQuiz()} className="start-button">PLAY A GAME</IonButton></td></tr>
                                    }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </>
        );
    }
}

export default withApplicationState(Scores);